import React, { useEffect, useState } from 'react';
import './portfolio.scss';
import { Link } from "react-router-dom";
import axios from 'axios';
import { URL } from '../../redux/url';
import { ClipLoader } from 'react-spinners';
import { ImageGallery } from "react-image-grid-gallery";
import { motion } from "framer-motion";

const Wedding = () => {
  const [weddingData, setWeddingData] = useState([]);
  const [loading, setLoading] = useState(true);

  const contentStyle = {
    color: '#fff',
    textAlign: 'left',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    fontSize: "30px"
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}/api/wedding`);
        setWeddingData(response.data);
      } catch (error) {
        console.error('Error fetching wedding data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="portfolio">
      <div style={contentStyle} className='album-header_text'>
        <motion.h1 initial={{ opacity:0 }} animate={{ opacity:1 }} transition={{ duration: 3 }}>Weddings</motion.h1>
      </div>
      {loading ? (
        <div className="loader">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
      ) : (
        <div className='wedding'>
          <ImageGallery
            imagesInfoArray={weddingData.flatMap(item =>
              item.pictures.map(picture => ({
                src: picture,
                alt: item.title || 'Wedding',
              }))
            )}
            columnCount={"auto"}
            columnWidth={300}
            gapSize={5}
          />
        </div>
      )}
    </div>
  );
};

export default Wedding;
