import React from "react";
import './footer.scss';
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF, FaWhatsapp } from "react-icons/fa6";

export default function Footer() {
  
  const currentYear = new Date().getFullYear();

  return (
    <>
        <div className="footer">
            <p>&copy; {currentYear} Dejifowowe Imagery. Developed by <a href="https://tadeyemi.co.uk" target="_blank" className="">Taiwo</a></p>

            <div className="footer-links">
              <Link className="text-base" to="https://wa.me/447587262933" target="_blank" aria-label="Visit Whatsapp page">
                    <FaWhatsapp/>
                </Link>
                <Link className="text-base" to="https://www.instagram.com/dejifowowe.imagery/" target="_blank" aria-label="Visit Instagram page">
                    <FaInstagram/>
                </Link>
                <Link className="text-base" to="https://www.facebook.com/share/JdA4HCrZpaDkb4Gg/?mibextid=LQQJ4d" target="_blank" aria-label="Visit Facebook page">
                    <FaFacebookF/>
                </Link>
            </div>
        </div>
    </>
  )
}
