import React, { useEffect, useState, useCallback } from "react";
import './portfolio.scss';
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import { URL } from '../../redux/url';
import ImageViewer from 'react-simple-image-viewer';
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";

const CustomCloseButton = ({ onClick }) => {
  return (
    <div className="custom-close-button" onClick={onClick}>
      <AiOutlineClose />
    </div>
  );
};

export default function PortfolioGallery() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const { id } = useParams();
  const [portfolioItem, setPortfolioItem] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    const fetchPortfolioItem = async () => {
      try {
        const response = await axios.get(`${URL}/api/portfolio/${id}/`);
        setPortfolioItem(response.data);
      } catch (error) {
        console.error('Error fetching portfolio item data:', error);
      }
    };

    fetchPortfolioItem();
  }, [id]);

  if (!portfolioItem) return <div>Loading...</div>;



  const images = portfolioItem.pictures.map(picture => `${picture}`);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="portfolio3">
      <div className='first-link'><Link to='#' onClick={() => navigate(-1)}><p><BsArrowLeft style={{ marginRight: '6px' }} /> back to gallery</p></Link><h2>Gallery</h2></div>
      <div className="image-container">
        {images.map((src, index) => (
        <img
          src={ src }
          onClick={ () => openImageViewer(index) }
          width="320"
          key={ index }
          style={{ margin: '2px' }}
          alt=""
        />
      ))}
      </div>
      

      {isViewerOpen && (
        <ImageViewer
          src={ images }
          currentIndex={ currentImage }
          disableScroll={ false }
          closeOnClickOutside={ true }
          onClose={ closeImageViewer }
          closeComponent={<CustomCloseButton />}
        />
      )}
      
    </div>
  );
}
