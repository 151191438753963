import React from "react";
import './home.scss';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { ImageGallery } from "react-image-grid-gallery";
import MotionWrap from "../motionWrapper";

const Home = () => {
    window.scrollTo(0, 0);

    const instaImages = [
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989818/ig5_yzfxwd.jpg", alt: 'instaImg1' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1717170684/344228073_790716422636897_1384094873901203774_n_azymtb.jpg", alt: 'instaImg2' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989818/ig8_ujg3pu.jpg", alt: 'instaImg3' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989818/ig1_vqawsl.jpg", alt: 'instaImg5' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989818/ig4_tz2ky8.jpg", alt: 'instaImg6' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989819/ig11_crku0g.jpg", alt: 'instaImg7' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989818/ig6_uhhsxj.jpg", alt: 'instaImg8' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1717001721/409505750_18247793992237223_2468509646823504182_n_fylxvr.jpg", alt: 'instaImg4' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989818/ig7_hhr5oe.jpg", alt: 'instaImg9' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989819/ig13_tpmwgt.jpg", alt: 'instaImg10' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989818/ig2_laf8xd.jpg", alt: 'instaImg11' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989819/ig10_yhhqmt.jpg", alt: 'instaImg12' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1717001721/354912938_3564746777176772_7695264326691626238_n_ul3bex.jpg", alt: 'instaImg13' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989819/ig12_gi4dgs.jpg", alt: 'instaImg14' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989819/ig16_qxw7xv.jpg", alt: 'instaImg15' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989820/ig18_f336dk.jpg", alt: 'instaImg16' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989820/ig17_sofq2r.jpg", alt: 'instaImg17' },
        { imageName: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989820/ig14_juvqdj.jpg", alt: 'instaImg18' },
      ]

    const imagesArray = [
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1718160129/portfolio/bg0nigwwxvzcp1k6ydbt.jpg", alt: 'image'},
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989818/ig5_yzfxwd.jpg", alt: 'instaImg1' },
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989818/ig8_ujg3pu.jpg", alt: 'instaImg2' },
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1717170684/344228073_790716422636897_1384094873901203774_n_azymtb.jpg", alt: 'instaImg2'},
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989819/ig12_gi4dgs.jpg", alt: 'instaImg14' },
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989820/ig14_juvqdj.jpg", alt: 'instaImg18' },
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1718159874/portfolio/mntbw7k2munejsxumcly.jpg", alt: 'image'},
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989820/ig17_sofq2r.jpg", alt: 'instaImg17' },
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989820/ig18_f336dk.jpg", alt: 'instaImg16' },
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1716989819/ig16_qxw7xv.jpg", alt: 'instaImg15' },
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1718160671/portfolio/w3mjacaimfnt4z76qehy.png", alt: 'instaImg15' },
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1717001721/354912938_3564746777176772_7695264326691626238_n_ul3bex.jpg", alt: 'instaImg13' },
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1718160128/portfolio/avywlhsvb00ikbbzssvc.jpg", alt: 'image'},
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1718159384/portfolio/ydyybq34yf8geyu3vloq.jpg", alt: 'image'},
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1718160434/portfolio/m2no37ioqh382qaieame.png", alt: 'image'},
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1718159385/portfolio/kke6yf5tn1c1tofjosl5.jpg", alt: 'image'},
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1718160435/portfolio/ghbj8mhqyfg2otes9qs5.png", alt: 'image'},
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1719421664/_DEJ8358_w08jnf.jpg", alt: 'image'},
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1719421851/DEJ_4747_lelkk9.jpg", alt: 'image'},
        { src: "https://res.cloudinary.com/dsnontai6/image/upload/v1719421849/DEJ_4763_qu0z5s.jpg", alt: 'image'},
      ]

    return(
        <div className="home">
            <section id="hero-section" class="relative">
                <video autoPlay muted loop playsInline id="myVideo" className="video-bg">
                    <source src="https://res.cloudinary.com/dsnontai6/video/upload/v1716933644/hero-video_wnixiv.mp4" type="video/mp4" />
                </video>
                <div class="overlay"></div>
                <div className="section-container">
                    <div className="section-content">
                    <MotionWrap>
                        <h1>let's capture<br />your beautiful<br />moments</h1>
                        <p className="decription">With unique approach to photography, I take ordinary moments and transform them into extraordinary memories that will last a lifetime.</p>
                        <Link to='/albums' className="btn">View Albums</Link>
                    </MotionWrap>
                    </div> 
                </div>
            </section>
    
            <section className="services-about">
                <motion.h1 whileInView={{ opacity: 1 }} whileHover={{ scale: 1.1 }} transition={{ duration: 0.5, type: 'tween' }}>About</motion.h1>
                <motion.p whileInView={{ opacity: 1 }} whileHover={{ scale: 1.1 }} transition={{ duration: 0.5, type: 'tween' }}>Welcome to our photography service! We capture beautiful, timeless photos for any occasion, including family portraits, weddings, photo shoots, and professional headshots. Our experienced photographers ensure stunning, personalized results. Contact us today to create lasting memories with our state-of-the-art equipment and flexible packages.</motion.p>
                <Link to='/services' className="btnn">Learn More</Link>
            </section>

            <section className="services-section">
            <MotionWrap>
                <ImageGallery
                    imagesInfoArray={imagesArray}
                    columnCount={"auto"}
                    columnWidth={350}
                    gapSize={5}
                    />
                </MotionWrap>
            </section>

            <section>
                <div className="segment-backgroung">
                    <div className="segment-content">
                        <h2>Capture Moments, Create timeless Memories</h2>
                        <p>Don't miss out on the opportunity to turn your memories into timeless art. Let's work together to create stunning visuals that tell your unique story.</p>
                        <Link to='/contact' className="btn">Get Started</Link>
                    </div>
                </div>
            </section>
            
            <section>
            <MotionWrap>
                <div className="instagram-section">
                    <h1>follow me on instagram</h1>
                    <div className="instagram-content">
                        <div className="instagram-grid">
                            {instaImages.map((images, index) => (
                                <img key={index} src={images.imageName} alt={images.alt} />
                            ))}
                        </div>
                        <div className="instagram-button">
                        <Link to='https://www.instagram.com/dejifowowe.imagery/' target='_blank' aria-label="Visit Instagram page" className='instagram-btn'>@dejifofowe.imagery</Link>
                        </div>
                    </div>
                </div>
                </MotionWrap>
            </section>
        </div>
    )
}

export default Home;
