import React from 'react';
import './service.scss';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import MotionWrap from '../motionWrapper';

export default function Service() {
    window.scrollTo(0, 0);
    const contentStyle = {
        color: '#fff',
        textAlign: 'left',
      //   backgroundImage: 'linear-gradient(rgba(90, 90, 90, 0.638),rgba(0, 0, 0, 0.552)), url("https://res.cloudinary.com/dsnontai6/image/upload/v1692791109/pexels-ksenia-chernaya-5691550_kszvw0.jpg")',
        backgroundSize: 'cover', // You can adjust these properties as needed
        backgroundPosition: 'center',
      };

    const services = [
        {servicesName: 'Regular Package', servicesPrice: '450', servicesImage: 'https://res.cloudinary.com/dsnontai6/image/upload/v1717176181/pexels-transtudios-3091636_vro4wp.jpg', servicesHour: "4 hours of photography coverage", serviceDesc: "Perfect for smaller weddings and out of season. This package is offered on a case by case basis depending on my schedule. Please enquire with your wedding plans to see if this is possible.", serviceDet1: "photographs edited in my signature style to download and share with family and friends."},
        {servicesName: 'Premuim Package', servicesPrice: '750', servicesImage: 'https://res.cloudinary.com/dsnontai6/image/upload/v1717175450/cindy-baffour-Q6f-MCXxr-Zk-Q-unsplash_mba0xw.jpg', servicesHour: "8 hours of photography coverage", serviceDesc: "Coverage for 8 hours can often be perfect for full wedding days. From tail end of prep through to just after first dance. This is a great package to cover just a little bit of everything to tell the story of the day.", serviceDet1: "photographs edited in my signature style to download and share with family and friends.", serviceDet2: "Any other extras can be added to the package."},
        {servicesName: 'Platinum Package', servicesPrice: '950', servicesImage: 'https://res.cloudinary.com/dsnontai6/image/upload/v1717175418/pexels-solliefoto-313707_zapkfs.jpg', servicesHour: "12 hours of photography coverage", serviceDesc: "Coverage for 12 hours. Covers everything you would need for a wedding day from prep to after first dance. With lots of wiggle room for timings, fireworks and anything else that could extend your day.", serviceDet1: "photographs edited in my signature style to download and share with family and friends.", serviceDet2: "Any other extras can be added to the package."}
    ]

    const shoots = [
        {shootName: 'lace', shootPrice: '200', shootOutfits: 'One Outfit', shootHours: 'Two Hours of shooting', shootImage: 'https://res.cloudinary.com/dsnontai6/image/upload/v1706921473/pexels-brett-sayles-2388569_pd4gqw.jpg'},
        {shootName: 'crystal', shootPrice: '350', shootOutfits: 'Two Outfits', shootHours: 'Three Hours of shooting', shootImage: 'https://res.cloudinary.com/dsnontai6/image/upload/v1717419072/pexels-cottonbro-5090830_i2knje.jpg'},
        {shootName: 'pearl', shootPrice: '550', shootOutfits: 'Three Outfits', shootHours: 'Four Hours of shooting', shootImage: 'https://res.cloudinary.com/dsnontai6/image/upload/v1717317434/pexels-alexander-dummer-37646-134469_a1h1tz.jpg'},
        {shootName: 'gold', shootPrice: '700', shootOutfits: 'Four Outfits', shootHours: 'Four Hours of shooting', shootImage: 'https://res.cloudinary.com/dsnontai6/image/upload/v1717419081/pexels-cottonbro-3888217_btptrp.jpg'}
    ]
  return (
    <>
        <div className="service">
                <div>
                    <div style={contentStyle} className='service-header_text'>
                        <motion.h1 initial={{ opacity:0 }} animate={{ opacity:1 }} transition={{ duration: 3 }}>Our Services</motion.h1>
                    </div>
                </div>

            <MotionWrap>
            <div className='wedding'>
                <div className='wed-head'><h2>Wedding Packages</h2></div>
                <div className='service-container'>
                    {services.map((service, index) =>(
                        <div key={index} className='service-container-item'>
                            <div className='service-title'><h3>{service.servicesName}</h3></div>
                            <div className='service-image'>
                                <img src={service.servicesImage} alt='image'/>
                            </div>
                            <div className='service-hour'><h3>{service.servicesHour}</h3></div>
                            <div className='service-desc'><p>{service.serviceDesc}</p></div>
                            <div className='service-dets'>
                                <p><strong>A secure online gallery</strong> containing your wedding pictures. </p>
                                <ul>
                                    <li>{service.serviceDet1}</li>
                                    <li><strong>Pre-wedding call</strong> to run through all aspects of the wedding day.</li>
                                    {/* <li>{service.serviceDet2}</li> */}
                                </ul>
                            </div>
                            <div className='service-price'><p>£{service.servicesPrice}</p></div>
                            <div className='service-button'><Link to='/contact'>Book Now</Link></div>
                        </div>
                    ))}
                </div>
            </div>
            </MotionWrap>

            <MotionWrap>
            <div className='wedding'>
                <div className='wed-head'><h2>Photo shoot Packages</h2></div>
                <div className='shoot-container'>
                    {shoots.map((shoot, index) =>(
                        <div key={index} className='shoot-container-item'>
                            <div className='service-title'><h3>{shoot.shootName}</h3></div>
                            <div className='service-image'>
                                <img src={shoot.shootImage} alt='image'/>
                            </div>
                            {/* <div className='service-hour'><h3>{shoot.shootOutfits}</h3></div> */}
                            {/* <div className='service-desc'><p>{shoot.serveDesc}</p></div> */}
                            <div className='service-dets'>
                                <p><strong>A secure online gallery</strong> containing your pictures. </p>
                                <ul>
                                    <li>{shoot.shootOutfits}</li>
                                    <li>{shoot.shootHours}</li>
                                    <p>** Extra £50 for shoots in a studio</p>
                                    {/* <li>{service.serviceDet2}</li> */}
                                </ul>
                            </div>
                            <div className='service-price'><p>£{shoot.shootPrice}</p></div>
                            <div className='service-button'><Link to='/contact'>Book Now</Link></div>
                        </div>
                    ))}
                </div>
            </div>
        </MotionWrap>
        </div>
    </>
  )
}
