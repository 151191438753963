import React, { useEffect, useState } from 'react';
import './portfolio.scss';
import { Link } from "react-router-dom";
import axios from 'axios';
import { URL } from '../../redux/url';
import { ClipLoader } from 'react-spinners';
import { motion } from "framer-motion";

const Portfolio = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [loading, setLoading] = useState(true);

  const contentStyle = {
    color: '#fff',
    textAlign: 'left',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    fontSize: "30px"
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${URL}/api/portfolio`); 
        setPortfolioData(response.data); 
        setLoading(false);
      } catch (error) {
        console.error('Error fetching portfolio data:', error);
        setLoading(false);
      }
    };

    fetchData(); 
  }, []);

  return (
    <div className="portfolio">
      <div style={contentStyle} className='album-header_text'>
        <motion.h1 initial={{ opacity:0 }} animate={{ opacity:2 }} transition={{ duration: 3 }}>Albums</motion.h1>
      </div>
      {loading ? (
        <div className="loader">
          <ClipLoader size={50} color={"#123abc"} loading={loading} />
        </div>
      ) : (
        <div className='portfolio-container'>
          {portfolioData.map(item => (
            <div key={item.id} className="portfolio-container_item" style={{ backgroundImage: `url(${item.pictures[0]})` }}>
              <Link to={`/albums/${item._id}`}>
                <h2>{item.title}</h2>
              </Link>
            </div>
          ))} 
        </div>
      )}
    </div>
  )
}

export default Portfolio;
