import {
    PORTFOLIO_DETAILS_FAIL,
    PORTFOLIO_DETAILS_REQUEST,
    PORTFOLIO_DETAILS_SUCCESS,
    PORTFOLIO_LIST_FAIL,
    PORTFOLIO_LIST_REQUEST,
    PORTFOLIO_LIST_SUCCESS,
  } from "../constants/portfolioConstants";
  
  // PORTFOLIO LIST
  export const portfolioListReducer = (state = { portfolio: [] }, action) => {
    switch (action.type) {
      case PORTFOLIO_LIST_REQUEST:
        return { loading: true, portfolio: [] };
      case PORTFOLIO_LIST_SUCCESS:
        return {
          loading: false,
          pages: action.payload.pages,
          page: action.payload.page,
          portfolio: action.payload.portfolio,
        };
      case PORTFOLIO_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // SINGLE PORTFOLIO
  export const portfolioDetailsReducer = (
    state = { portfolio: { reviews: [] } },
    action
  ) => {
    switch (action.type) {
      case PORTFOLIO_DETAILS_REQUEST:
        return { ...state, loading: true };
      case PORTFOLIO_DETAILS_SUCCESS:
        return { loading: false, portfolio: action.payload };
      case PORTFOLIO_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
