import { motion } from "framer-motion";

const MotionWrap = ({ children }) => {
    return (
        <motion.div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 1 }}
            // Add more props as needed
        >
            {children}
        </motion.div>
    );
};

export default MotionWrap;
