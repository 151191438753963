import React from 'react';
import './contact.scss';
import { Link } from "react-router-dom";
import emailjs from 'emailjs-com';
import { motion } from "framer-motion";
import MotionWrap from '../motionWrapper';

export default function Contact() {
    window.scrollTo(0, 0);

    const contentStyle = {
        color: '#fff',
        textAlign: 'left',
      //   backgroundImage: 'linear-gradient(rgba(90, 90, 90, 0.638),rgba(0, 0, 0, 0.552)), url("https://res.cloudinary.com/dsnontai6/image/upload/v1692791109/pexels-ksenia-chernaya-5691550_kszvw0.jpg")',
        backgroundSize: 'cover', // You can adjust these properties as needed
        backgroundPosition: 'center',
      };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_biiaov4', 'template_qi6tjw6', e.target, 'CLajyCf531id-RRBn')
            .then((result) => {
                console.log(result.text);
                alert('Message sent successfully!');
            }, (error) => {
                console.log(error.text);
                alert('Failed to send the message, please try again.');
            });

        e.target.reset();
    };

  return (
    <>
        <div className='contact'>
            <div style={contentStyle} className='contact-row'>
                <motion.h1 initial={{ opacity:0 }} animate={{ opacity:1 }} transition={{ duration: 3 }}>Contact Us</motion.h1>
            </div>
            <MotionWrap>
            <div className='contact-container'>
                <h4>We'd love to hear from you!</h4>

                <div className='dets'>
                    <p>Email: dejifowoweimagery@gmail.com</p>
                    <p>Phone: 07393 139376</p>
                </div>

                <form class="my-form" onSubmit={sendEmail}>
                    <div class="container">
                        <ul>
                            <li>
                                <select>
                                    <option selected disabled>-- Select Job Type --</option>
                                    <option>Event</option>
                                    <option>Proposal Shoot</option>
                                    <option>Maternity</option>
                                    <option>Headshots</option>
                                    <option>Family Portrait</option>
                                    <option>Profession Portrait</option>
                                    <option>Proposal Shoot</option>
                                    <option>Headshots</option>
                                    <option>Photo Session (e.g pre-graduation, birthday shoot)</option>
                                    <option>Pre Wedding Shoot</option>
                                    <option>Wedding Full Coverage</option>
                                    <option>Wedding Photography</option>
                                    <option>Wedding Video Coverage</option>
                                    <option>Other</option>      
                                </select>
                            </li>
                        <li>
                            <input type="text" placeholder="Name" name="user_name" required />  
                        </li>
                        <li>
                            <div class="grid grid-2">
                            <input type="email" placeholder="Email" name="user_email" required />  
                            <input type="tel" placeholder="Phone" name="user_phone" required/>
                            </div>
                        </li>
                        <li>
                            <div class="grid grid-2">
                            <input type="text" placeholder="Place of Event" name="user_place" required />
                            <input type="date" placeholder='Main Shoot \ Event Date' name='date' required />
                            </div>
                        </li>   
                        <li>
                            <select>
                                <option selected disabled>-- Select Time of Shoot --</option>
                                <option>Day</option>
                                <option>Evening Shoot</option>
                                <option>Night</option>
                                <option>Overnight</option>      
                            </select> 
                        </li>  
                        <li>
                            <textarea placeholder="Message" name="user_message"></textarea>
                        </li>   
                        {/* <li>
                            <input type="checkbox" id="terms" />
                            <label for="terms">I have read and agreed with <a href="">the terms and conditions.</a></label>
                        </li>   */}
                        <li>
                            <div class="grid grid-3">
                            <div class="required-msg">REQUIRED FIELDS</div>
                            <button class="btn-grid" type="submit">
                                <span class="back">
                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/email-icon.svg" alt="" />
                                </span>
                                <span class="front">SUBMIT</span>
                            </button>
                            {/* <button class="btn-grid" type="reset">
                                <span class="back">
                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/eraser-icon.svg" alt="" />
                                </span>
                                <span class="front">RESET</span>
                            </button>  */}
                            </div>
                        </li>    
                        </ul>
                    </div>
                </form>  
            </div>
            </MotionWrap>
        </div>
    </>
  )
}
