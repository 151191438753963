import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import Footer from './components/footer/footer';
import Navbar from './components/navbar/navbar';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Home from './pages/home/home';
import Portfolio from './pages/portfolio/portfolio';
import PortfolioAlbum from './pages/portfolio/portfolioAlbum';
import PortfolioGallery from './pages/portfolio/portfolioCover';
import Portrait from './pages/portfolio/portrait';
import Wedding from './pages/portfolio/wedding';
import Service from './pages/services/service';


function App() {
  return (
    <div className="App">
    <BrowserRouter forceRefresh>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/portraits' element={<Portrait />} />
        <Route path='/weddings' element={<Wedding />} />
        <Route path='/albums' element={<Portfolio />} />
        <Route path='/services' element={<Service />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/albums/:id' element={<PortfolioAlbum />} />
        <Route path='/albums/:id/gallery' element={<PortfolioGallery />} />
      </Routes>
      <Footer />
    </BrowserRouter>
    </div>
  );
}

export default App;
