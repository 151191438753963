import { createStore, combineReducers, applyMiddleware } from "redux";
import {thunk} from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import { portfolioDetailsReducer, portfolioListReducer } from "./reducers/portfolioReducers";

const reducer = combineReducers({
    portfolioList: portfolioListReducer,
    portfolioDetails: portfolioDetailsReducer,
});


const middleware = [thunk];

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;