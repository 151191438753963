import React from 'react';
import './about.scss';
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import MotionWrap from '../motionWrapper';

export default function About() {
    window.scrollTo(0, 0);
  return (
    <>
        <div className='about'>
            <div className='masthead'>
                <motion.p initial={{ opacity:0 }} animate={{ opacity:1 }} transition={{ duration: 3 }} className="masthead-intro">Hello, I am</motion.p>
                <motion.h1 initial={{ opacity:-3 }} animate={{ opacity:2 }} transition={{ duration: 7 }} className="masthead-heading">Ayomideji Ajifowowe!</motion.h1>
            </div>
            <MotionWrap>
            <section className='intro'>
                <h1>Introduction</h1>
                <p>Hello! I am Ayomideji Boluwatifekori Ajifowowe, a passionate and purpose-driven <strong>Nigerian wedding, portrait, and lifestyle photographer</strong>. Photography is more than a profession for me; it is a profound journey of capturing and preserving moments that tell unique stories. As a pictorial storyteller, content creator, and image manipulator, I dedicate myself to creating images that resonate deeply and live with every scene I sight through my lens.</p>
                <p>My love for photography began early in life, sparked by a fascination with how a single image can evoke powerful emotions and memories. Over the years, I have honed my skills and developed a distinctive style that blends artistic creativity with technical expertise. My work is characterized by vibrant colors, emotive compositions, and an acute attention to detail.</p>
                <p>As a wedding photographer, I am honored to be part of one of the most significant days in a couple's life. I focus on capturing the genuine emotions, candid moments, and intricate details that make each wedding unique. My goal is to provide my clients with a beautiful visual narrative that they can treasure forever, a testament to their love story.</p>
                <p>In portrait photography, I strive to reveal the true essence and personality of my subjects. Creating a relaxed and comfortable environment, I allow individuals to express themselves naturally, resulting in authentic and captivating portraits. Each session is an opportunity to highlight the unique beauty and character of each person I photograph.</p>
            </section>
            </MotionWrap>
            <MotionWrap>
            <section className='where-Im-from'>
                <h1>Where I'm from</h1>
                <p>I am from Ibadan, a vibrant city in Nigeria known for its rich history, cultural heritage, and bustling markets. Ibadan is one of the largest cities in Nigeria. Currently, I reside in Liverpool, a dynamic city in the United Kingdom famous for its maritime history, musical heritage, and passionate football culture. </p>
                <div className='logo'>
                    <img src= 'https://res.cloudinary.com/dsnontai6/image/upload/v1706918625/Deji_Fowowe_logo_transparent_black_ehavfa.png'
                    alt='image' />
                </div>
            </section>
            </MotionWrap>
        </div>
    </>
  )
}
