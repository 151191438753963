import React, {useState} from 'react'
import './navbar.scss'
import { Link, NavLink, useLocation } from "react-router-dom";
import { HiMenuAlt3, HiX } from 'react-icons/hi';
import { RxHamburgerMenu } from "react-icons/rx";
import { motion } from 'framer-motion';
import { RiArrowDropDownLine } from "react-icons/ri";

const Navbar = () => {
    const [toggle, setToggle] = useState(false);
    const [dropdown, setDropdown] = useState(false);
  
  return (
    <nav className='app__navbar'>
        <div className='app__navbar-logo'>
            <Link to='/'><img src="https://res.cloudinary.com/dsnontai6/image/upload/v1706918625/Deji_Fowowe_logo_transparent_black_ehavfa.png" /></Link>
        </div>
        <ul className="app__navbar-links">
          <NavLink to='/' activeClassName="active"><li className="app__flex p-text">home</li></NavLink>
          {/* <NavLink to='/albums' activeClassName="active"><li className="app__flex p-text">Albums</li></NavLink> */}
          <div className='dropdown'>
            <button className='dropbtn' activeClassName="active">Portfolio <RiArrowDropDownLine /></button>
            <div className='dropdown-content'>
              <NavLink to='/albums'><li className="app__flex p-text">Albums</li></NavLink>
              <NavLink to='/weddings'><li className="app__flex p-text">Weddings</li></NavLink>
              <NavLink to='/portraits'><li className="app__flex p-text">Portraits</li></NavLink>
            </div>
          </div>
          <NavLink to='/services' activeClassName="active"><li className="app__flex p-text">Services</li></NavLink>
          <NavLink to='/about-us' activeClassName="active"><li className="app__flex p-text">About</li></NavLink>
          <NavLink to='/contact' activeClassName="active"><li className="app__flex p-text">Contact</li></NavLink>
      </ul>

        <div className='app__navbar-menu'>
        <RxHamburgerMenu onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ y: [-300, 0] }}
            transition={{ duration: 1, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              <li><Link to='/' onClick={() => setToggle(false)}>Home</Link></li>
              <li>
                <button className="dropdown-toggle drop-btn" onClick={() => setDropdown(!dropdown)}>Portfolio<RiArrowDropDownLine /></button>
                {dropdown && (
                  <ul className="dropdown">
                    <li><Link to='/albums' onClick={() => { setToggle(false); setDropdown(false); }}>Albums</Link></li>
                    <li><Link to='/weddings' onClick={() => { setToggle(false); setDropdown(false); }}>Weddings</Link></li>
                    <li><Link to='/portraits' onClick={() => { setToggle(false); setDropdown(false); }}>Portraits</Link></li>
                  </ul>
                )}
              </li>
              <li><Link to='/services' onClick={() => setToggle(false)}>Services</Link></li>
              <li><Link to='/about-us' onClick={() => setToggle(false)}>About</Link></li>
              <li><Link to='/contact' onClick={() => setToggle(false)}>Contact</Link></li>
            </ul>
          </motion.div>
        )}
        </div>
    </nav>
  )
}

export default Navbar
